import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/es-MX';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';

import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import {defineCustomElements} from '@profabric/web-components/loader';

import { routes } from '@/app.routes';
import { uiReducer } from '@utils/index';
import { SeguridadService } from './services/seguridad.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import 'moment/locale/es';

defineCustomElements();
registerLocaleData(localeEn, 'es-MX');

(window as any).PF = {
  config: {
      mode: 'bs4'
  }
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    importProvidersFrom(
        BrowserModule,
        StoreModule.forRoot({ui: uiReducer}),
        ToastrModule.forRoot({
          timeOut: 3000,
          positionClass: 'toast-top-right',
          preventDuplicates: true
        }),
        BrowserAnimationsModule
    ), 
    provideAnimationsAsync(),
    {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
    provideMomentDateAdapter(MY_FORMATS),
  ]
};
