import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {SeguridadService} from '@/services/seguridad.service';

export const EsConsultorGuard: CanActivateFn = (
    route : ActivatedRouteSnapshot,
    state : RouterStateSnapshot
) => {

    const seguridadService = inject(SeguridadService);
    const router = inject(Router);
    var rol = seguridadService.obtenerRol();
    var cli = seguridadService.obtenerCliente();

    if (cli.toUpperCase() != 'SHOPOLOGY' && (state.url.toUpperCase().indexOf('EVIDENCIA') >= 0 || state.url.toUpperCase().indexOf('INMEDIATA') >= 0)) {
        router.navigate(['/home']);
        return false;
    }

    if (rol === 'Shopology' || rol=== 'Admin') {
        return true;
    }

    if(rol.toUpperCase() === 'HABIT'){
        router.navigate(['/home/conversaciones']);
        return true;
    }

    router.navigate(['/home']);
    return false;
}
