import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SeguridadService} from '@/services/seguridad.service';

export const ValidarLogueoGuard: CanActivateFn = () => {

    const seguridadService = inject(SeguridadService);
    const router = inject(Router);

    if (!seguridadService.estaLogueado()) {
        return true;
    }

    router.navigateByUrl('/home');
    return false;
}
