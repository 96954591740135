import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SeguridadService} from '@/services/seguridad.service';

export const EsSoporteGuard: CanActivateFn = () => {

    const seguridadService = inject(SeguridadService);
    const router = inject(Router);

    const rol = seguridadService.obtenerRol();

    if (rol === 'Shopology' || rol === 'Admin' || rol === 'Soporte') {
        return true;
    }

    router.navigate(['/home']);
    return false;
}
