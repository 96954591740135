import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SeguridadService} from '@/services/seguridad.service';

export const EsHabitGuard: CanActivateFn = () => {

    const seguridadService = inject(SeguridadService);
    const router = inject(Router);

    const rol = seguridadService.obtenerRol();
    const cliente = seguridadService.obtenerCliente();

    let roles = ["Admin", 'Habit', 'Shopology'];

    if (cliente == "shopology" && roles.includes(rol)) {
        return true;
    }

    router.navigate(['/home']);
    return false;
}
